<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("addpricelist") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required">
              รหัสpricelist</label
            >
            <input v-model="form.code" class="form-control mb-4" />
          </div>

          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required">
              ชื่อ pricelist</label
            >
            <input v-model="form.name" class="form-control mb-4" />
          </div>
        </div>

        <div class="row text-end">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-sm btn-light btn-success"
              style="font-size: 14px; font-weight: bold"
              @click="addItem()"
            >
              {{ $t("addon") }}
            </button>
            <AddItem
              :dialogAddItem="dialogAddItem"
              :warehouseId="form.fromWarehouseId"
              @addProduct="addProduct"
              @closeDialogAddItem="closeDialogAddItem"
            />
          </div>
        </div>

        <div class="table-responsive" style="padding-top: 3px">
          <a-table :dataSource="dataItemsShow" :columns="columns">
            <template #headerCell="{ column }">
              <template v-if="column.dataIndex === 'no'">
                {{ $t("no") }}
              </template>
              <template v-if="column.dataIndex === 'code'">
                {{ $t("code") }}
              </template>
              <template v-if="column.dataIndex === 'Sale Price'">
                {{ $t("Sale Price") }}
              </template>
              <template v-if="column.dataIndex === 'Spqty'">
                {{ $t("Spqty") }}
              </template>
              <template v-if="column.dataIndex === 'U/M'">
                {{ $t("U/M") }}
              </template>
              <template v-if="column.dataIndex === 'productname'">
                {{ $t("productname") }}
              </template>
            </template>

            <template #bodyCell="{ column, record, index }">
              <template v-if="column.dataIndex === 'no'">
                {{ record.no }}
              </template>
              <template v-if="column.dataIndex === 'code'">
                <span v-if="record.product">
                  {{ record.product ? record.product.code : record.code }}
                </span>
              </template>
              <!-- <template v-if="column.dataIndex === 'cost'">
                <input
                  type="number"
                  class="form-control"
                  v-model="record.sale_price"
                />
              </template> -->
              <template v-if="column.dataIndex === 'Sale Price'">
                {{ record.sale_price || record.sale_price }}
              </template>
              <template v-if="column.dataIndex === 'Spqty'">
                {{ record.spqty }}
              </template>
              <template v-if="column.dataIndex === 'U/M'">
                <select
                  class="form-select mb-4"
                  v-model="record.u_m"
                  id="validationTooltip01"
                  disabled
                >
                  <option v-for="item in units" :key="item" :value="item.id">
                    {{ item.code }} |
                    {{ item.name }}
                  </option>
                </select>
              </template>
              <template v-if="column.dataIndex === 'productname'">
                {{ record.product ? record.product.name : record.name }}
              </template>
              <template v-if="column.dataIndex === 'action'">
                <div class="row">
                  <button
                    type="button"
                    class="
                      btn btn-link btn-color-muted btn-active-color-danger
                      m-0
                      p-0
                    "
                    @click="confirmDelete(record, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </div>
              </template>
            </template>
          </a-table>
          <!-- <table
            class="
              table table-hover table-rounded table-striped
              border
              gy-7
              gs-7
            "
          >
            <thead>
              <tr
                style="width: 100%"
                class="
                  fw-bold
                  fs-5
                  text-gray-800
                  border-bottom-2 border-gray-200
                  mw-200px
                "
              >
                <th>{{ $t("no") }}</th>
                <th>{{ $t("code") }}</th>
                <th>Sale Price</th>
                <th>Spqty</th>
                <th>U/M</th>
                <th>{{ $t("productname") }}</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="dataItemsShow.length < 1">
                <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
              </tr>
              <tr v-for="(item, index) in dataItemsShow" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.code }}</td>
                <td>{{ item.sale_price || item.sale_price }}</td>
                <td>{{ item.spqty }}</td>
                <td>{{ item.u_m }}</td>
                <td>{{ item.name }}</td>

                <td>
                  <div>
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-danger
                        m-0
                        p-0
                      "
                      @click="confirmDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table> -->
        </div>

        <!-- <div class="row d-flex justify-content-center mt-4">
          <div class="col-sm-8">
            <label for="selectMainProduct" class="form-label">หมายเหตุ</label>
            <input class="form-control mb-4" />
            <label for="selectMainProduct" class="form-label"
              >โอนเข้าคลัง</label
            >
            <input class="form-control mb-4" />
          </div>

          <div class="col-sm-4">
            <label for="selectMainProduct" class="form-label"
              >มูลค่ารวม(ก่อนVAT)</label
            >
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">ส่วนลดรวม</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">VAT</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label"
              >มูลค่าสุทธิ</label
            >
            <input disabled class="form-control" />
          </div>
        </div> -->
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-light btn-primary me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submit()"
          >
            {{ $t("save") }}
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import AddItem from "./components/dialogAddItem.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import transferApi from "@/api/transfer/";

export default {
  components: {
    AddItem,
  },
  setup() {
    document.title = "J&N | สร้างใบโอนสินค้า";
  },
  data: () => ({
    columns: [
      // { title: "no", dataIndex: "no" },
      { title: "code", dataIndex: "code" },
      { title: "productname", dataIndex: "productname" },
      { title: "Sale Price", dataIndex: "Sale Price", width: 150 },
      { title: "Spqty", dataIndex: "Spqty", width: 150 },
      { title: "U/M", dataIndex: "U/M" },
      { title: "action", dataIndex: "action" },
    ],
    searchProduct: "",
    isSubmit: false,
    isValidRetailPrice: false,
    dialogAddItem: false,
    form: {
      fromWarehouseId: 0,
      toWarehouseId: 0,
      name: "",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
    warehouses: [],
    dataItemsShow: [],
    isValid: false,
    units: [],
  }),

  async created() {
    await this.getAllUnit();
    await this.getAllPriceLsit();
  },
  methods: {
    async getAllUnit() {
      let companyId = parseInt(localStorage.getItem("companyId"));
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.unit.getAll(companyId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.units = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    addProduct(item) {
      console.log("addProduct", item);
      this.dataItemsShow.push(item);
    },
    async getAllPriceLsit() {
      let responseData = [];
      try {
        responseData = await whApi.pricelist.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.pricelists = responseData.data;
      }
    },
    async submit() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.validateForm();
          await this.checkFormIsEmpty();
          this.isSubmit = true;
          if (this.isValid == true) {
            let responseData = [];
            let body = {
              ...this.form,
              priceListItems: this.dataItemsShow,
              companyId: parseInt(localStorage.getItem("companyId")),
            };
            console.log("body adddddd", body);
            // try {
            responseData = await whApi.pricelist.create(body);
            // } catch (error) {
            //   console.log(error);
            // }
            if (responseData.response_status === "SUCCESS") {
              Swal.fire({
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              }).then(() => {
                this.$router.push("/warehouse/PRICELIST");
              });
            }
          }
        }
      });
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    checkFormIsEmpty() {
      // !this.form.code
      //   ? (this.isValid = false)
      //   :
      !this.form.name
        ? (this.isValid = false)
        : !this.form.code
        ? (this.isValid = false)
        : !this.dataItemsShow
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    cancel() {
      this.$router.go(-1);
    },
    addItem() {
      this.dialogAddItem = true;
    },
    closeDialogAddItem() {
      this.dialogAddItem = false;
    },

    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await whApi.warehouse.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
  },
};
</script>
